var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "password-reset-form inner-wrapper"
  }, [_c('div', {
    staticClass: "accounts-form"
  }, [_c('h1', {
    staticClass: "accounts-form__small-bi-header cursor",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/introduction/main');
      }
    }
  }, [_vm._v(" Qpick ")]), _c('h1', {
    staticClass: "accounts-form__header"
  }, [_vm._v("비밀번호 변경")]), _c('div', {
    staticClass: "accounts-form_texts"
  }, [_c('outlinedTextField', {
    staticClass: "accounts-form__outlined-text",
    attrs: {
      "placeholder": "새로운 비밀번호",
      "type": "password",
      "case": "password"
    },
    on: {
      "value": _vm.setPassword,
      "validate": _vm.validate
    }
  }), _vm.error.password ? _c('p', {
    staticClass: "accounts-form__form-error"
  }, [_vm._v(" " + _vm._s(_vm.error.password) + " ")]) : _vm._e(), _c('outlinedTextField', {
    staticClass: "accounts-form__outlined-text",
    attrs: {
      "placeholder": "새로운 비밀번호 확인",
      "type": "password",
      "case": "passwordConfirm"
    },
    on: {
      "value": _vm.setPasswordConfirmation,
      "validate": _vm.validate
    }
  }), _vm.error.passwordConfirmation ? _c('p', {
    staticClass: "accounts-form__form-error"
  }, [_vm._v(" " + _vm._s(_vm.error.passwordConfirmation) + " ")]) : _vm._e()], 1), _c('button', {
    staticClass: "accounts-form__submit",
    on: {
      "click": _vm.change
    }
  }, [_vm._v("변경하기")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }