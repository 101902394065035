<template>
  <div class="wrapper">
    <div class="password-reset-form inner-wrapper">
      <div class="accounts-form">
        <h1
          class="accounts-form__small-bi-header cursor"
          @click="$router.push('/introduction/main')"
        >
          Qpick
        </h1>
        <h1 class="accounts-form__header">비밀번호 변경</h1>
        <div class="accounts-form_texts">
          <outlinedTextField
            placeholder="새로운 비밀번호"
            @value="setPassword"
            class="accounts-form__outlined-text"
            type="password"
            case="password"
            @validate="validate"
          ></outlinedTextField>
          <p class="accounts-form__form-error" v-if="error.password">
            {{ error.password }}
          </p>
          <outlinedTextField
            placeholder="새로운 비밀번호 확인"
            @value="setPasswordConfirmation"
            class="accounts-form__outlined-text"
            type="password"
            case="passwordConfirm"
            @validate="validate"
          ></outlinedTextField>
          <p
            class="accounts-form__form-error"
            v-if="error.passwordConfirmation"
          >
            {{ error.passwordConfirmation }}
          </p>
        </div>
        <button class="accounts-form__submit" @click="change">변경하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import userAPI from "@/api/accounts/user.js";
import "@/assets/scss/accounts/basis.scss";
import {chkValidationPassword} from "@/utils/user.js";
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  data() {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      token: "",
      error: {
        password: "",
        passwordConfirmation: ""
      },
      checkedPassword: false,
      checkedPasswordConfirm: false
    };
  },
  created() {
    this.$EventBus.$on("alertCancel", event => {
      switch (event) {
        case "password-reset":
          this.$router.push("/accounts/password-reset");
          break;

        case "password-reset:replace":
          this.$router.replace("/accounts/password-reset");
          break;

        case "login":
          this.$router.push("/accounts/login");
          break;

        case "back":
          this.$router.go(-1);
          break;
      }
    });
  },
  mounted() {
    this.email = this.$router.currentRoute.query.email;
    this.token = this.$router.currentRoute.query.token;

    userAPI.resetPasswordCheck(this.email, this.token).then(res => {
      if (res.status !== 204) {
        this.setAlert({
          isVisible: true,
          message: res.data.errors[0].userMsg,
          event: "password-reset:replace"
        });
      }
    });
  },
  methods: {
    change() {
      if (!this.checkedPassword || !this.checkedPasswordConfirm) {
        return;
      }

      userAPI
        .resetPassword(
          this.email,
          this.token,
          this.password,
          this.passwordConfirmation
        )
        .then(res => {
          if (res.status === 204) {
            this.setAlert({
              isVisible: true,
              message: "비밀번호가 변경되었습니다.",
              event: "login"
            });
          } else {
            this.setAlert({
              isVisible: true,
              message: res.data.errors[0].userMsg,
              event: "back"
            });
          }
        });
    },
    setPassword(v) {
      this.password = v;
    },
    setPasswordConfirmation(v) {
      this.passwordConfirmation = v;
    },
    //input 유효성 검사
    validate(obj) {
      const _case = obj.case;
      const _value = obj.value;

      if (_case === "password") {
        let resPassword = chkValidationPassword(obj.value);

        if (!resPassword.result) {
          this.error.password = resPassword.msg;
          this.checkedPassword = false;
        } else {
          this.error.password = "";
          this.checkedPassword = true;
        }
      }

      if (_case === "passwordConfirm") {
        if (!_value) {
          this.error.passwordConfirmation = "비밀번호 확인을 입력해주세요.";
          this.checkedPasswordConfirm = false;
        } else if (this.password !== _value) {
          this.error.passwordConfirmation = "비밀번호가 일치하지 않습니다.";
          this.checkedPasswordConfirm = false;
        } else {
          this.error.passwordConfirmation = "";
          this.checkedPasswordConfirm = true;
        }
      }
    },
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  components: {
    outlinedTextField: () =>
      import("@/components/accounts/outlined-text-field.vue")
  }
};
</script>

<style>
.password-reset-form .accounts-form__header {
  margin-bottom: 100px;
}
</style>
